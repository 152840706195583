import { createAction, props } from '@ngrx/store';
import { User } from "./user.model";


export const saveUser = createAction(
  '[User API] Save User',
  props<{ user: User }>()
);

export const saveUserSuccess = createAction(
  '[User API] Save User Success',
  props<{ user: User }>()
);

export const saveUserFailure = createAction(
  '[User API] Save User Failure',
  props<{ error: string }>()
);

export const loadUser = createAction('[User Page] Load User');

export const loadUserSuccess = createAction(
  '[User API] User Load Success',
  props<{ user: User }>()
);

export const loadUserFailure = createAction(
  '[User API] User Load Failure',
  props<{ error: string }>()
);
